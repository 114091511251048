$yc-yellow: #ffcc33;

$light-grey: rgba(0, 0, 0, 0.3);
$grey: rgba(0, 0, 0, 0.4);
$dark-grey: rgba(0, 0, 0, 0.5);

$top-offset: 20px;
$left-offset: 20px;
$bottom-offset: 70px;

$w1: 30px;
$w2: 45px;
$floorplan-width: 300px;
$floorplan-height: 300px;
// $floorplan-width: 20vw;
// $floorplan-height: 20vh;

// $floorplan-width-mobile: 200px;
// $floorplan-height-mobile: 200px;
$floorplan-width-mobile: 10rem;
$floorplan-height-mobile: 10rem;

.buttonText {
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft JhengHei;
}

.view-menu-container {
    z-index: 90;
}

.fpview-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: $top-offset;
    left: $left-offset;
    z-index: 100;

    width: 50px;
    height: 50px;

    border: hidden;
    border-radius: 50%;
    
    color: white;
    background: rgba(0, 0, 0, 0);
    
    cursor: pointer;
    &:hover {
        background-color: $grey;
    }
}

.fpview-button-mobile {

}

.floorplan-topview-container {
    display: flex;
    flex-direction: column;

    position: fixed;
    left: $left-offset;
    bottom: 0px;

    width: $floorplan-width;
    height: calc(#{$floorplan-height} + #{$w1} + #{$w2});

    background: rgba(0, 0, 0, 0);

    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.floorplan-topview-container-mobile {
    display: flex;
    flex-direction: column;

    position: fixed;
    left: 0px;
    bottom: $bottom-offset;

    width: $floorplan-width-mobile;
    height: calc(#{$floorplan-height-mobile} + #{$w1} + #{$w2});

    background: rgba(0, 0, 0, 0);

    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.floorplan-topview-container-mobile.Open {
    transition-delay: 0.25s;
}

.menu-toggle-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: $w1;

    border: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    color: white;
    background: $dark-grey;

    cursor: pointer;
}

.menu-toggle-button-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: $w1;

    border: hidden;
    border-top-right-radius: 20px;

    color: white;
    background: $dark-grey;

    cursor: pointer;
}

.menu-toggle-button-closed-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    left: 0px;
    bottom: calc(#{$bottom-offset} + #{$w2});

    width: $w1;
    height: $floorplan-width-mobile;

    border: hidden;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    color: white;
    background: $dark-grey;

    cursor: pointer;

    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}


.menu-toggle-button-closed-mobile.Open{
    transition-delay: 0.25s;
}

.floorplan-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: $floorplan-height;

    border: hidden;

    color: white;
    background: $light-grey;

    cursor: pointer;
}

.floorplan-button-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: $floorplan-height-mobile;

    border: hidden;

    color: white;
    background: $light-grey;

    cursor: pointer;
}

.topview-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: $w2;

    border: hidden;

    color: white;
    background: $dark-grey;

    cursor: pointer;
    &:hover {
        color: $yc-yellow;
        .icon-3d-view path {
            stroke: $yc-yellow;
        }
        .icon-3d-view .fill {
            fill: $yc-yellow;
        }
    }
}

.topview-button-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: $w2;

    border: hidden;
    border-bottom-right-radius: 20px;

    color: white;
    background: $dark-grey;

    cursor: pointer;
}

