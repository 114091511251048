@import 'src/asset/css/colors';
.viewerContainer {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    // bottom: 0;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 80px;
    z-index: 100;
}

.Scene-close {
    width: 0%;
    height: 100%;
    position: absolute;
    bottom: 0;
    // display: none;
}

.Scene-open {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.mainScene-open {
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.mainScene-open-mobile {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
}

.secondScene-open {
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.secondScene-open-mobile{
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
}

.select-style-signal-rightfull {
    width: 50px;
    height: 20px;
    left: 50%;
    padding: 5px 16px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0 -42px;
    position: fixed;
    top: 25px;
    line-height: 20px;
    text-align: center;
    // font-weight: bold;
    font-family: Microsoft JhengHei;
}

.select-style-signal-double {
    width: 50px;
    height: 20px;
    left: 50%;
    padding: 5px 16px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0 25px;
    position: fixed;
    top: 25px;
    line-height: 20px;
    text-align: center;
    // font-weight: bold;    
    font-family: Microsoft JhengHei;
}

.select-style-signal-mobile {
    width: 50px;
    height: 20px;
    right: 2%;
    top: 50%;
    padding: 5px 16px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 10px 0 0 0;
    position: fixed;
    line-height: 20px;
    text-align: center;
    // font-weight: bold;    
    font-family: Microsoft JhengHei;
}

.select-style-board {
    display: flex;
    z-index: 101;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 120px;
    height: 44px;
    padding: 6px 15px 6px 14px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    cursor: pointer;
    
    .select-style-button-name {
        width: 60px;
        height: 44px;
        margin: 0 0 0 10px;
        font-family: "PingFangTC";
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 44px;
        letter-spacing: normal;
        color: #fff;
    }
}

.select-style-board:hover{
    background-color: #FCB630
}

.select-style-board-iconlist {
    z-index: 101;
    position: fixed;
    right: 2%;
    bottom: 10%;
    display: inline-table;
    .select-style-board-iconblock {
        width: 36px;
        height: 36px;
        border-radius: 30px;
        margin: 0 0 16px 0;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.5);
        img {
            width: 90%;
            padding: 5%;
        }
    }
}

.select-style-board-open {
    display: flex;
    z-index: 101;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: auto;
    padding: 6px 15px 6px 14px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    .select-style-button-name {
        width: 80px;
        height: 44px;
        margin: 0 0 0 10px;
        font-family: "PingFangTC";
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 44px;
        letter-spacing: normal;
        color: #fff;
        
    }
    .select-style-button-name.selected {
        color: #fcb630;
    }

    .select-style-button-name:hover{
        color: #FCB630;
        cursor: pointer;
    }
    .closeStyleIcon{
        cursor: pointer;
        width: 24px;
        height: 44px;
    }
}

.select-style-board-mobile {
    display: block;
    z-index: 101;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 62px;
    height: 62px;
    padding: 6px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    .select-style-button-name {
        font-family: "PingFangTC";
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #fff;
        margin: 2px;
    }
}

.select-style-board-iconlist-mobile {
    z-index: 101;
    position: fixed;
    right: 2%;
    bottom: 6%;
    // width: 24px;
    display: inline-table;
    .select-style-board-iconblock {
        width: 24px;
        height: 24px;
        border-radius: 30px;
        margin: 0 0 16px 0;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.5);
        img {
            width: 90%;
            padding: 5%;
        }
    }
}

.select-style-board-open-mobile {
    display: flex;
    z-index: 101;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 44px;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    padding: 0 10px 0 10px;
    .select-style-button-name-list {
        width: calc(100% - 80px);
        display: flex;
        justify-content: center;
    }
    .select-style-button-name {
        width: 70px;
        height: 44px;
        margin: 0 0 0 10px;
        font-family: "PingFangTC";
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 44px;
        letter-spacing: normal;
        color: #fff;
    }
    .selected {
        color: #fcb630;
    }
}

.select-style {
    display: flex;
    flex-direction: column;
    z-index: 101;
    right: 0%;
    position: fixed;
    width: 300px;
    max-width: 80%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    @media only screen and (orientation: portrait) {
        display: block;
        flex-direction: column;
        z-index: 100;
        bottom: 0%;
        position: fixed;
        width: 100%;
        max-width: none;
        height: 80%;
        border-radius: 10px 10px 0 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        z-index: 100;
        bottom: 0%;
        position: fixed;
        width: 100%;
        max-width: none;
        height: 80%;
        border-radius: 10px 10px 0 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
}

.select-style-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    margin: 14px 65px 16px 92px;
}

.close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    color: black;
    font-size: xx-large;
}

.styleboardclose {
    .closeBtn {
        cursor: pointer;
        position: fixed;
        left: calc(50% + 20px);
        top: 20px;
        color: black;
        font-size: xx-large;
        @media only screen and (hover: none) and (pointer: coarse) and (orientation: portrait) {
            top: calc(50% + 20px);
            left: 20px;
            cursor: pointer;
            position: fixed;
            color: black;
            font-size: xx-large;
        }
    }
}

.select-style-button-list {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    margin-top: 25px;
    display: flex;
    padding: 0px;
    height: 100%;
    overflow: auto;
    @media only screen and (orientation: portrait) {
        justify-content: flex-start;
        margin: 0;
        gap: 0px;
        height: calc(100% - 51px);
    }
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        flex-direction: row;
        justify-content: center;
    }
}

.select-style-button {
    z-index: 100;
    color: white;
    font-size: 14px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
    padding: 0;
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        width: 166px;
        height: 80%;
    }
    img {
        width: 80%;
        border-radius: 25px;
        border-style: solid;
        border-color: transparent;
    }
    img:hover {
        border-color: #EEE;
    }
    .select-style-button-name {
        margin: 0;
        @media only screen and (orientation: portrait) {
            padding: 10px 0;
        }
    }
}

.show-style-button-old {
    position: absolute;
    width: 120px;
    height: 50px;
    z-index: 100;
    color: rgba(255, 204, 51, 1.0);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        bottom: 128px;
        right: 40px;
    }
    @media only screen and (max-width: 1024px) {
        right: 10px;
        top: 10px;
    }
}

.show-ruler-button {
    position: absolute;
    width: 40px;
    z-index: 100;
    background: transparent;
    border-style: none;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        bottom: 130px;
        right: 160px;
    }
    @media only screen and (max-width: 1024px) {
        right: 120px;
        top: 15px;
    }
}

.cancel-button {
    position: absolute;
    top: 100px;
    right: 40px;
    width: 40px;
    z-index: 100;
    background: transparent;
    border-style: none;
    cursor: pointer;
}

// select dropdown
.show-dropdown-close {
    position: absolute;
    width: 150px;
    height: 50px;
    z-index: 100;
    color: $yc-black-text;
    background: $yc-dropdown-gray;
    border-radius: 15px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        top: 25px;
        left: 20px;
    }
}

.show-dropdown-close-mobile {
    position: absolute;
    width: 200px;
    height: 40px;
    z-index: 100;
    color: $yc-black-text;
    background: $yc-dropdown-gray;
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    top: 25px;
    left: 20px;
}

.show-dropdown-close-mobile-bt {
    position: absolute;
    width: 100px;
    height: 40px;
    z-index: 100;
    color: $yc-black-text;
    background: transparent;
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    top: 25px;
    left: 20px;
}

.dropdown-close-down {
    position: absolute;
    top: 45%;
    right: 15px;
    border: solid $yc-dropdown-arrow;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
}

.dropdown-close-mobile-frame {
    position: absolute;
    top: 20%;
    right: 50%;
    border: solid $yc-dropdown-arrow;
    //border: solid #d8d8d8;
    border-width: 0 2px 0 0;
    display: inline-block;
    padding: 12px;
}

.dropdown-close-value {
    position: absolute;
    top: 25%;
    left: 12%;
    right: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.show-dropdown-open {
    position: absolute;
    width: 150px;
    height: 50px;
    z-index: 100;
    color: $yc-black-text;
    background: $yc-dropdown-gray;
    border-radius: 15px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        top: 20px;
        left: 20px;
    }
}

.show-dropdown-open:hover {
    background: $yc-dropdown-hover;
}

.show-dropdown-close:hover {
    background: $yc-dropdown-hover;
}

.dropdown-open-button {
    position: absolute;
    width: 80px;
    height: 35px;
    z-index: 100;
    text-align: left;
    color: $yc-black-text;
    background: $yc-dropdown-gray;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (min-width: 1025px) {
        left: 20px;
    }
}

.dropdown-open-button:hover {
    background: $yc-dropdown-hover;
}

.dropdown-open-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.dropdown-open-up {
    position: absolute;
    top: 40%;
    left: 48%;
    border: solid $yc-dropdown-arrow;
    border-width: 2px 0 0 2px;
    display: inline-block;
    padding: 2px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
}

.dropdown-open-down {
    position: absolute;
    top: 40%;
    left: 48%;
    border: solid $yc-dropdown-arrow;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
}

.dropdown-open-point {
    position: absolute;
    top: 42%;
    right: 15%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 7px;
    background: $yc-yellow;
    display: inline-block;
}

.multifloor-dropdown {
    position: absolute;
    z-index: 100;
    left: 20px;
    border-radius: 60px;
    background-image: linear-gradient(to bottom, rgba(216, 216, 216, 0), #ffffff 48%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    justify-content: center;
    // @media only screen and (min-width: 1366px) {
    //     bottom: 130px;
    // }   
    @media only screen and (max-width: 1366px) {
        bottom: 0px;
    }
}

.multifloor-button-select {
    position: absolute;
    z-index: 100;
    opacity: 0.8;
    border-radius: 100px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    border: solid 2px #fdcc33;
    background-color: #000000;
    color: white;
    @media only screen and (min-width: 1366px) {
        font-size: 100%;
    }
    @media only screen and (max-width: 1366px) {
        font-size: 50%;
    }
}

.multifloor-button {
    position: absolute;
    z-index: 95;
    opacity: 0.39;
    border-radius: 100px;
    border: 0;
    background-color: #000000;
    color: white;
    @media only screen and (min-width: 1366px) {
        font-size: 100%;
    }
    @media only screen and (max-width: 1366px) {
        font-size: 50%;
    }
}

.multifloor-button-up {
    position: absolute;
    border: solid #d8d8d8;
    display: inline-block;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
    @media only screen and (min-width: 1366px) {
        border-width: 4px 0 0 4px;
        padding: 8px;
        left: 45%;
        top: 5%;
    }
    @media only screen and (max-width: 1366px) {
        border-width: 4px 0 0 4px;
        padding: 5px;
        left: 42%;
        top: 5%;
    }
}

.multifloor-button-down {
    position: absolute;
    border: solid #d8d8d8;
    display: inline-block;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
    @media only screen and (min-width: 1366px) {
        border-width: 0 4px 4px 0;
        padding: 8px;
        left: 45%;
        bottom: 5%;
    }
    @media only screen and (max-width: 1366px) {
        border-width: 0 4px 4px 0;
        padding: 5px;
        left: 42%;
        bottom: 5%;
    }
}

.waterprint {
    position: absolute;
    pointer-events: none;
    z-index: 90;
    display: flex;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.loadingAnim {
    position: absolute;
    pointer-events: none;
    z-index: 100;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: #4e4e4e67;
}

.qrcode-board {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 120;
    top: 0;
}

.qrcode-board-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0.5;
}

.qrcode-close {
    margin: -70px 0 35px 250px;
    width: 36px;
    height: 36px;
    opacity: 0.5;
    background-color: #000000;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.qrcode-board-image {
    width: 250px;
    height: 250px;
    z-index: 115;
    padding: 15px 26px 16px;
    background-color: white;
    opacity: 1;
    img {
        width: 80%;
        margin: 0 10%;
    }
    h3 {
        height: 44px;
        margin: 0 0 9px 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }
}

.tool-bar {
    position: fixed;
    top: 25px;
    right: 25px;
    width: auto;
    height: 25px;
    padding: 5px 10px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    button {
        background: transparent;
        border-style: none;
        
        img {
            width: 22px;
            height: 22px;
        }

        .activeToolbutton{
            cursor: pointer;
        }
    }
    // .show-style-button {
    //     display: none;
    // }
    // .functional-tool-bar {
    //     button {
    //         background: transparent;
    //         border-style: none;
    //         cursor: pointer;
    //     }
    //     // mobile 模式，工具列靠右
    //     @media only screen and (hover: none) and (pointer: coarse),
    //     (max-width: 1366px) {
    //         // break point < 414px 隱藏按鈕
    //         @media only screen and (max-width: 414px) {
    //             .mobile-hide-button {
    //                 display: none;
    //             }
    //         }
    //         @media only screen and (max-height: 414px) {
    //             .mobile-hide-button {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}

.verify-section {
    position: fixed;
    right: 0;
    top: 75px;
    padding: 20px;
    z-index: 100;
    .enterNumber-box {
        position: fixed;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        width: 300px;
        height: 100px;
        padding: 12px;
        background-color: rgb(255, 255, 255);
        .top-section {
            width: 100%;
            height: 50%;
            display: flex;
            button {
                color: #fff;
                font-size: 24px;
                background-color: transparent;
            }
            h3 {
                height: 100%;
                padding: 0px 10px 0px 0px;
                margin: 0;
                width: 60%;
            }
            textarea {
                height: 20px;
                width: 35%;
            }
        }
        .bottom-section {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: flex-end;
            button {
                color: black;
                font-size: 19px;
                background-color: transparent;
                border: 2px solid #4e4e4e;
                padding: 0px 10px;
                margin: 0 10px;
                height: 80%;
            }
            h3 {
                height: 100%;
                margin: -10px 10px;
            }
        }
    }
    button {
        border: none;
        cursor: pointer;
    }
    .trigger-btn {
        width: 100px;
        height: 50px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
        &:hover {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
    .check-box {
        position: fixed;
        right: 50%;
        top: 50%;
        height: 500px;
        transform: translate(50%, -50%);
        max-width: 325px;
        padding: 12px;
        background-color: gray;
        word-wrap: break-word;
        text-align: center;
        overflow: auto;
    }
    .confirm-box{
        position: fixed;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        width: 300px;
        height: 150px;
        background-color: rgb(255, 255, 255);
        border: solid;
        .title{
            width: 300px;
            height: 50px;
            background-color: rgb(122, 122, 122);
            line-height: 50px;
            label {
                padding: 10px;
                font-weight: bold;
            }
        }
        .content{
            width: 300px;
            height: 70px;
            label{
                width: 90%;
                position: absolute;
                padding: 0px 10px;
            }
            
        }
        .footer{
            width: 300px;
            height: 30px;
            text-align: right;
            button{
                margin: 0px 5px;
                border: solid;
            }
        }
    }
    .reason-box{
        position: fixed;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        width: 350px;
        height: 200px;
        background-color: rgb(255, 255, 255);
        border: solid;
        .title{
            width: 100%;
            height: 50px;
            background-color: rgb(122, 122, 122);
            line-height: 50px;
            label {
                padding: 10px;
                font-weight:bold;
            }
        }
        .content{
            width: 100%;
            height: 120px;
            text-align: center;
            label{
                width: 90%
            }
            textarea {
                height: 60px;
                width: 90%;
            }
        }
        .footer{
            width: 100%;
            height: 30px;
            text-align: right;
            text-align: right;
            button{
                margin: 0px 5px;
                border: solid;
            }
        }
    }
    .complete-text,
    .error-text {
        border: none;
        width: 100px;
        height: 50px;
        cursor: default;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
    }
    .error-text {
        color: rgb(253, 74, 74)
    }
    .back-section {
        width: 100%;
        display: inline-flex;
        vertical-align: middle;
        button {
            color: #fff;
            font-size: 24px;
            background-color: transparent;
        }
        h3 {
            height: 100%;
            margin: 0;
            color: #ffffff;
            left: 30%;
            position: relative;
        }
    }
    .back-section-index {
        width: 100%;
        height: 43%;
        max-width: 325px;
        display: inline-block;
        word-wrap: break-word;
        text-align: left;
        h3.s1{
            height: 35%;
            color: rgb(0, 0, 0);
            margin-bottom: 0;
            margin-top: "7px";
        }
        h3.s2{
            height: 100%;
            color: rgb(242, 247, 0);
            margin: 0;
        }
    }
    .action-section {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.pass {
            background-color: #000000;
            button {
                height: 100%;
                width: 100%;
                color: #FFCC33;
            }
        }
        &.fail {
            background-color: #fff;
            button {
                height: 100%;
                width: 100%;
                color: red;
            }
        }
        button {
            background-color: transparent;
            font-size: 48px;
            font-weight: bold;
        }
    }
    .input-section {
        width: 100%;
        .hint {
            color: rgb(253, 74, 74);
            font-size: 16px;
        }
        label {
            display: inline-block;
            font-size: 18px;
            color: #fff;
            margin: 4px 0;
        }
        textarea {
            font-size: 18px;
        }
    }
    .submit-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            background-color: #000;
            color: #fff;
            padding: 4px 16px;
            font-size: 18px;
            font-weight: bold;
        }
    }
    &.done {
        display: none;
    }
}