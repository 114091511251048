// pink
$pink-istaging: #ec0974;

// gray
$alto: #d8d8d8;
$silver: #bababa;
$scarpa-flow: #58585a;
$tundora: #4a4a4a;
$ship-gray: #444446;

// purple
$eminence: #662e91;

$white: #fff;
$black: #000;

//yc
// $yc-yellow: #FFCC33;
$yc-yellow: rgb(255, 204, 51);

// dropdown
$yc-dropdown-white: rgba(255, 255, 255, 0.9);
$yc-dropdown-hover: rgba(255, 255, 255, 0.7);
$yc-dropdown-gray: rgba(255, 255, 255, 0.4);
$yc-dropdown-arrow: #333;
$yc-black-text: #555;

// style block
$yc-style-gray: rgba(0, 0, 0, 0.4);
$yc-style-light-gray: rgba(0, 0, 0, 0.2);
